var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"fill-height"}},[_c('v-layout',{attrs:{"align-center":"align-center","justify-center":"justify-center"}},[_c('v-flex',{staticClass:"login-form text-xs-center"},[_c('v-card',{staticClass:"mx-auto",class:{'my-12':_vm.$vuetify.breakpoint.mdAndUp},attrs:{"light":"light","max-width":"500","elevation":"0"}},[_c('v-list',[_c('v-list-item',{staticClass:"text-center"},[_c('v-list-item-content',[_c('h1',{staticClass:"auth-title text-h4"},[_vm._v(_vm._s(_vm.$t('signUp.RegisterToStartLearning')))]),_c('div',{staticClass:"text-center text-h6",staticStyle:{"color":"#6f7074"}},[_vm._v(" "+_vm._s(_vm.$t('login.LoginToYourAccount'))+" "),_c('v-btn',{staticClass:"px-0 font-weight-medium text-h6",attrs:{"text":"","color":"#79b530","to":"/login"}},[_vm._v(_vm._s(_vm.$t('header.Login')))])],1)])],1)],1),_c('v-card-text',{staticClass:"py-0 px-0"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"firstName","rules":{required:true,max:161,min:2,alpha_spaces:true
}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.firstName'),"type":"text","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.signup.first_name),callback:function ($$v) {_vm.$set(_vm.signup, "first_name", $$v)},expression:"signup.first_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"LastName","rules":{required:true,max:161,min:2,alpha_spaces:true
}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.LastName'),"type":"text","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.signup.last_name),callback:function ($$v) {_vm.$set(_vm.signup, "last_name", $$v)},expression:"signup.last_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"phoneNumber","rules":"required|mobileregex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.phoneNumber'),"type":"text","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.signup.mobile),callback:function ($$v) {_vm.$set(_vm.signup, "mobile", $$v)},expression:"signup.mobile"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"YourGrade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.grades,"item-text":function (grades) { return grades[_vm.$i18n.locale].name; },"item-value":"id","label":_vm.$t('validations.fields.YourGrade'),"type":"text","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.signup.grade_id),callback:function ($$v) {_vm.$set(_vm.signup, "grade_id", $$v)},expression:"signup.grade_id"}})]}}],null,true)}),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExist == true),expression:"isExist == true"}],attrs:{"dense":"","text":"","type":"error"}},[_vm._v("Email Already Exists")]),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.email'),"type":"email","required":"","single-line":"","outlined":"","height":"50"},on:{"keyup":function($event){return _vm.checkEmail()},"blur":function (){_vm.signup.email=_vm.signup.email.toLowerCase()}},model:{value:(_vm.signup.email),callback:function ($$v) {_vm.$set(_vm.signup, "email", $$v)},expression:"signup.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":"required|min:8|passwordRegex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":_vm.show1 ? 'text' : 'password',"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"label":_vm.$t('validations.fields.password'),"required":"","single-line":"","outlined":"","height":"50"},on:{"click:append":function($event){_vm.show1 = !_vm.show1},"contextmenu":function($event){$event.preventDefault();},"copy":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();}},model:{value:(_vm.signup.password),callback:function ($$v) {_vm.$set(_vm.signup, "password", $$v)},expression:"signup.password"}})]}}],null,true)}),_c('v-btn',{staticClass:"my-4",attrs:{"large":"","block":"","loading":_vm.loading,"color":"primary","type":"submit","disabled":invalid || (_vm.isExist == true)}},[_vm._v(_vm._s(_vm.$t('signUp.Register')))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }