var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"fill-height"}},[_c('v-layout',{attrs:{"align-center":"align-center","justify-center":"justify-center"}},[_c('v-flex',{staticClass:"login-form text-xs-center"},[_c('v-card',{staticClass:"mx-auto",attrs:{"light":"light","max-width":"700","elevation":"0"}},[_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|min:8|passwordRegex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":_vm.show1 ? 'text' : 'password',"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"label":_vm.$t('validations.fields.password'),"required":"","single-line":"","outlined":"","height":"50"},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.current_password),callback:function ($$v) {_vm.current_password=$$v},expression:"current_password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"NewPassword","rules":"required|min:8|passwordRegex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":_vm.show2 ? 'text' : 'password',"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"label":_vm.$t('validations.fields.NewPassword'),"required":"","single-line":"","outlined":"","height":"50"},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"ConfirmPassword","rules":"required|min:8|passwordRegex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":_vm.show3 ? 'text' : 'password',"append-icon":_vm.show3 ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"label":_vm.$t('validations.fields.ConfirmPassword'),"required":"","single-line":"","outlined":"","height":"50"},on:{"click:append":function($event){_vm.show3 = !_vm.show3}},model:{value:(_vm.c_password),callback:function ($$v) {_vm.c_password=$$v},expression:"c_password"}})]}}],null,true)}),_c('v-btn',{attrs:{"large":"","block":"","loading":_vm.loading,"color":"primary","type":"submit","disabled":invalid}},[_vm._v(_vm._s(_vm.$t('validations.fields.Submit')))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }