<template>
  <!-- For Bunny -->
  <div>
    <div style="position:relative;padding-top:56.25%;">
      <iframe 
        :src="data.video" 
        loading="lazy" 
        style="border:0;position:absolute;top:0;height:100%;width:100%;" 
        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" 
        allowfullscreen="true">
      </iframe>
    </div>
  </div>
  <!-- <video-player
    class="vjs-fluid"
    ref="videoPlayer"
    :options="playerOptions"
    :playsinline="true"
    @ready="playerReadied"
  >
  </video-player> -->
</template>

<script>
// import 'videojs-contrib-quality-levels';
// import videojsqualityselector from 'videojs-hls-quality-selector';
export default {
   props: ['data'],
  data() {
    return {
      // playerOptions: {
      //   // videojs options
      // //   height: "350",
      //   muted: true,
      //   language: "en",
      //   playbackRates: [0.7, 1.0, 1.5, 2.0],
      //   sources: [
      //     {
      //       src: this.data.video,
      //       withCredentials: true,
      //     },
      //   ],
      //   //  poster: "/static/images/author.jpg",
      // },
    };
  },
  mounted() {
    console.log(this.data.video);
  },
  computed: {
    // player() {
    //   return this.$refs.videoPlayer.player;
    // },
  },
  methods: {
    // playerReadied(player) {
    //   player.hlsQualitySelector = videojsqualityselector;
    //   player.hlsQualitySelector({
    //      displayCurrentQuality: true,
    //   });
    //   player.fluid(true)

    // },
  },
};
</script>

<style>
.vjs-fluid{
       padding-top : 0
}
</style>