var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"fill-height"}},[_c('v-layout',{attrs:{"align-center":"align-center","justify-center":"justify-center"}},[_c('v-flex',{staticClass:"login-form text-xs-center"},[_c('v-card',{staticClass:"mx-auto",attrs:{"light":"light","max-width":"700","elevation":"0"}},[_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"first name","rules":{required:true,max:20,min:2,alpha_spaces:true
}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.firstName'),"type":"text","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"middle name","rules":{required:true,max:20,min:2,alpha_spaces:true


}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":_vm.$t('validations.fields.middleName'),"required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.middle_name),callback:function ($$v) {_vm.middle_name=$$v},expression:"middle_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"last name","rules":{required:true,max:20,min:2,alpha_spaces:true
}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.LastName'),"type":"text","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.email'),"type":"email","required":"","single-line":"","outlined":"","height":"50"},on:{"blur":function (){_vm.email=_vm.email.toLowerCase()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"ParentNumber","rules":{required:true,
           mobileregex:true,
          numeric:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.ParentNumber'),"type":"text","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.parent_number),callback:function ($$v) {_vm.parent_number=$$v},expression:"parent_number"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"phoneNumber","rules":{required:true,
  mobileregex:true,          numeric:true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.phoneNumber'),"type":"text","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Dateofbirth"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","error-messages":errors,"label":_vm.$t('validations.fields.Dateofbirth'),"single-line":"","outlined":""},on:{"click:append":on.click},model:{value:(_vm.dob),callback:function ($$v) {_vm.dob=$$v},expression:"dob"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale =='ar'?'ar-EG-u-nu-latn':'',"active-picker":_vm.activePicker,"max":(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),"min":"1990-01-01"},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.saveDateOfBirth},model:{value:(_vm.dob),callback:function ($$v) {_vm.dob=$$v},expression:"dob"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Your Grade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.grades,"item-text":function (grades) { return grades[_vm.$i18n.locale].name; },"item-value":"id","label":_vm.$t('validations.fields.YourGrade'),"type":"text","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.grade_id),callback:function ($$v) {_vm.grade_id=$$v},expression:"grade_id"}})]}}],null,true)}),_c('v-btn',{attrs:{"large":"","block":"","loading":_vm.loading,"color":"primary","type":"submit","disabled":invalid}},[_vm._v(_vm._s(_vm.$t('validations.fields.Submit')))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }