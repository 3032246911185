var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"fill-height"}},[_c('v-layout',{attrs:{"align-center":"align-center","justify-center":"justify-center"}},[_c('v-flex',{staticClass:"login-form text-xs-center"},[_c('v-card',{staticClass:"mx-auto ",class:{'my-12':_vm.$vuetify.breakpoint.mdAndUp},attrs:{"light":"light","max-width":"500","elevation":"0"}},[_c('v-list',[_c('v-list-item',{staticClass:"text-center"},[_c('v-list-item-content',[_c('h1',{staticClass:"auth-title"},[_vm._v(_vm._s(_vm.$t('becomeTeacher.becomeTitle')))])])],1)],1),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{ref:"afterSubmit",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"name","rules":{required:true,min:3,max:30,alpha_spaces:true
}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.name'),"type":"text","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._l((_vm.invalidData),function(err,index){return _c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.invalidData.length),expression:"invalidData.length"}],key:index+'dfsdf',attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(_vm._s(err))])}),_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.email'),"type":"email","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"phoneNumber","rules":{required:true,
            mobileregex:true,
          numeric:true
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.phoneNumber'),"type":"text","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":"required|min:8|passwordRegex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":_vm.show1 ? 'text' : 'password',"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"label":_vm.$t('validations.fields.password'),"required":"","single-line":"","outlined":"","height":"50"},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"ConfirmPassword","rules":"required|confirmed:password|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":_vm.show2 ? 'text' : 'password',"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"label":_vm.$t('validations.fields.ConfirmPassword'),"required":"","single-line":"","outlined":"","height":"50"},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.c_password),callback:function ($$v) {_vm.c_password=$$v},expression:"c_password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Grade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.grades,"item-text":function (grades) { return grades[_vm.$i18n.locale].name; },"item-value":"id","label":_vm.$t('validations.fields.Grade'),"type":"text","required":"","multiple":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.grade_id),callback:function ($$v) {_vm.grade_id=$$v},expression:"grade_id"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"subjects","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.subjects,"item-text":function (subjects) { return subjects[_vm.$i18n.locale].name; },"item-value":"id","label":_vm.$t('validations.fields.subjects'),"type":"text","required":"","multiple":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.subject_id),callback:function ($$v) {_vm.subject_id=$$v},expression:"subject_id"}})]}}],null,true)}),_c('v-btn',{attrs:{"large":"","block":"","loading":_vm.loading,"color":"primary","type":"submit","disabled":invalid}},[_vm._v(_vm._s(_vm.$t('validations.fields.Submit')))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }